export interface Question {
  key: string
  heading: string
  description?: string
  component: string
  suffix?: string
  helper?: string
  helperText?: string
  options?: {
    key: string
    label: string
  }[],
  min?: number
  max?: number
  marks?: Record<number, string>,
  formatter?: (value: number) => string
}

export const questions: Question[] = [{
  key: 'gender',
  heading: 'Sinu sugu',
  component: 'RadioGroup',
  options: [{
    key: 'male',
    label: 'Mees'
  }, {
    key: 'female',
    label: 'Naine'
  }]
}, {
  key: 'age',
  heading: 'Sinu vanus',
  component: 'SliderInput',
  suffix: 'a',
  formatter: (nr) => {
    return nr + 'a'
  },
  min: 16,
  max: 99
}, {
  key: 'experience',
  heading: 'Kui pikk on sinu investeerimiskogemus?',
  component: 'VerticalOptions',
  options: [{
    key: 'none',
    label: 'Pole alustanud'
  }, {
    key: 'under_1',
    label: 'Alla 1a'
  },
  ...Array(9).fill(0).reduce((all, _, i) => {
    all.push({
      key: (i + 1).toString(),
      label: (i + 1) + 'a'
    })
    return all
  }, []),
  {
    key: 'over_10',
    label: '10a ja rohkem'
  }]
}, {
  key: 'portfolio_size',
  heading: 'Kui suur on hinnanguliselt Sinu investeerimisportfell 2023 lõpu seisuga?',
  description: 'Palun sisesta oma portfelli <strong>neto väärtus</strong> (ehk kui kasutad investeeringute võimendamiseks laenu, siis lahuta laenude jääk bruto portfelli suurusest). <strong>Ära arvesta oma eluaset portfelli varade hulka</strong>.<br /><br /> Vali slideri abil kõige lähem number, või kirjuta number tekstikasti.',
  component: 'PortfolioSize'
}, {
  key: 'asset_classes',
  heading: 'Sinu portfelli varaklasside osakaalud',
  component: 'AssetAllocation',
  formatter: (nr) => {
    return nr + '%'
  }
}, {
  key: 'portfolio_return',
  heading: 'Kui suur on sinu portfelli 2023 aasta tootlus?',
  description: 'Kui sa ei tea, kuidas portfelli tootlust arvutada, siis vajuta all pool olevale sinisele nupule või <a target="_blank" href="https://taavi.golive.ee/investori-kasiraamat/kuidas-arvutada-tootlust/">loe juhendit siit</a>',
  component: 'SliderInput',
  helper: 'ReturnCalculator',
  helperText: 'Tootluse kalkulaator',
  suffix: '%',
  min: -100,
  max: 100,
  formatter: (nr) => {
    if (nr >= 100) {
      return '>= 100%'
    }
    return nr + '%'
  }
}, {
  key: 'savings_rate',
  heading: 'Kui suur on hinnanguliselt sinu 2023 aasta keskmine säästumäär?',
  description: 'Kui sa ei tea, mis on või kuidas säästumäära arvutada, siis <a target="_blank" href="https://taavi.golive.ee/investori-kasiraamat/mis-on-saastumaar/">loe juhendit siit</a>.',
  component: 'SliderInput',
  helper: 'SavingsRateCalculator',
  helperText: 'Säästumäära kalkulaator',
  suffix: '%',
  min: 0,
  max: 100,
  formatter: (nr) => {
    return nr + '%'
  }
}, {
  key: 'main_income_source',
  heading: 'Sinu peamine sissetulek pärineb:',
  component: 'VerticalOptions',
  options: [{
    key: 'salary',
    label: 'Palgatööst'
  }, {
    key: 'entrepreneurship',
    label: 'Ettevõtlusest'
  }, {
    key: 'investments',
    label: 'Investeeringutest'
  }, {
    key: 'pension',
    label: 'Pensionist'
  }, {
    key: 'state_support',
    label: 'Riigi toetustest'
  }, {
    key: 'other',
    label: 'Muu'
  }]
}, {
  key: 'next_year_prediction',
  heading: 'Usun, et 2024 aastal on minu portfelli tootlus suurem kui 2023 aastal?',
  component: 'RadioGroup',
  options: [{
    key: 'yes',
    label: 'Jah'
  }, {
    key: 'same',
    label: 'Sama'
  }, {
    key: 'no',
    label: 'Ei'
  }]
}, {
  key: 'previous_year',
  heading: 'Kas vastasid ka eelmine aasta Finantsvabaduse grupi küsitlusele?',
  component: 'RadioGroup',
  options: [{
    key: 'yes',
    label: 'Jah'
  }, {
    key: 'no',
    label: 'Ei'
  }, {
    key: 'dont_remember',
    label: 'Ei mäleta'
  }]
}, {
  key: 'best_investment',
  heading: 'Mis oli sinu parim ja halvim investeering 2023 aastal?',
  component: 'TextArea',
  max: 1000
}, {
  key: 'next_year_goals',
  heading: 'Mis on sinu järgmise aasta eesmärgid / strateegia?',
  component: 'TextArea',
  max: 1000
}, {
  key: 'free_text',
  heading: 'Soovid, ettepanekud Finantsvabaduse grupi administraatoritele. Muud mõtted.',
  component: 'TextArea',
  max: 1000
}, {
  key: 'email',
  heading: 'Kui soovid küsitluse tulemuste avaldamise kohta teavitust, siis kirjuta siia oma e-posti aadress',
  description: 'E-posti aadressi salvestame küsitluse vastustest eraldi Mailchimpi serverisse, ehk seost e-posti addressi ja vastuste vahel ei teki.',
  component: 'TextInput',
  max: 200
}]
