import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "slider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_slider = _resolveComponent("n-slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_n_slider, {
        value: _ctx.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        step: "mark",
        marks: _ctx.question.marks,
        max: _ctx.question.max,
        min: _ctx.question.min,
        formatTooltip: _ctx.formatTooltip
      }, null, 8, ["value", "marks", "max", "min", "formatTooltip"])
    ])
  ]))
}