import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-174b2228"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_radio_button = _resolveComponent("n-radio-button")!
  const _component_n_radio_group = _resolveComponent("n-radio-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_n_radio_group, {
      value: _ctx.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      name: "group",
      size: "large"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.options, (option) => {
          return (_openBlock(), _createBlock(_component_n_radio_button, {
            key: option.key,
            value: option.key,
            label: option.label
          }, null, 8, ["value", "label"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}