
import { computed, defineComponent, ref, watch } from 'vue'
import { NSlider, NInput } from 'naive-ui'
import { validator, validatorEvents } from './parts'

export default defineComponent({
  props: ['question', 'data', 'helperValue'],
  components: {
    NSlider,
    NInput
  },
  emits: [
    ...validatorEvents
  ],
  setup (_, context) {
    const value = ref(_.data[_.question.key] ?? 0)
    const validations = computed(() => {
      return value.value < _.question.min || value.value > _.question.max
    })

    const { isValid } = validator(validations, context)

    const save = () => {
      return value.value
    }

    watch(() => _.helperValue, (val) => {
      value.value = Math.max(Math.min(val, _.question.max), _.question.min)
    })

    const update = (val: string) => {
      if (isNaN(parseInt(val))) {
        value.value = val
      } else {
        value.value = parseInt(val)
      }
    }

    return {
      value,
      save,
      update,
      validations,
      isValid
    }
  }
})
