import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17459bf6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "question-area" }
const _hoisted_2 = { class: "heading" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "submit" }
const _hoisted_5 = {
  key: 0,
  class: "validation-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_button = _resolveComponent("n-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.question.heading), 1),
    (_ctx.question.description)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          innerHTML: _ctx.question.description
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.question.helper)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.question.helper), _mergeProps({ key: 1 }, { question: _ctx.question, data: _ctx.data }, {
          isVisible: _ctx.helperVisible,
          "onUpdate:isVisible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.helperVisible) = $event)),
          onValue: _ctx.onHelperValue
        }), null, 16, ["isVisible", "onValue"]))
      : _createCommentVNode("", true),
    (_ctx.question.helperText)
      ? (_openBlock(), _createBlock(_component_n_button, {
          key: 2,
          style: {"max-width":"300px"},
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.helperVisible = Date.now())),
          type: "info"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.question.helperText), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.question.component), _mergeProps({
      key: _ctx.question.key,
      ref: "questionRef"
    }, { question: _ctx.question, data: _ctx.data, helperValue: _ctx.helperValue }, {
      onIsValid: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isValid = $event)),
      onErrorMessage: _cache[3] || (_cache[3] = ($event: any) => (_ctx.message = $event))
    }), null, 16)),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("a", {
        class: "back",
        href: "#",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
      }, "Eelmine küsimus"),
      (_ctx.message && _ctx.message !== true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.message), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_n_button, {
        onClick: _ctx.submit,
        size: "large",
        type: "success",
        disabled: !_ctx.isValid || _ctx.isLoading,
        loading: _ctx.isLoading
      }, {
        default: _withCtx(() => [
          _createTextVNode("Edasi")
        ]),
        _: 1
      }, 8, ["onClick", "disabled", "loading"])
    ])
  ]))
}