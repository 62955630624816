import { createApp } from 'vue'
import App from './App.vue'

createApp(App).mount('#app')

function domContentLoaded () {
  setTimeout(console.log.bind(console, '\n%cTere sõber, ära hacki!', 'color:red;font-size:50px;font-weight:bold;text-shadow: 1px 1px 0px black, 1px -1px 0px black, -1px 1px 0px black, -1px -1px 0px black;'))
  setTimeout(console.log.bind(console, 'Kui tahad juttu puhuda, kirjuta mulle taavi@golive.ee'))
}

document.addEventListener('DOMContentLoaded', domContentLoaded)
