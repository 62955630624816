
import { defineComponent, ref } from 'vue'
import { NButton } from 'naive-ui'
import AssetAllocation from './question-types/AssetAllocation.vue'
import RadioGroup from './question-types/RadioGroup.vue'
import SliderInput from './question-types/SliderInput.vue'
import SliderOptions from './question-types/SliderOptions.vue'
import VerticalOptions from './question-types/VerticalOptions.vue'
import PortfolioSize from './question-types/PortfolioSize.vue'
import TextArea from './question-types/TextArea.vue'
import TextInput from './question-types/TextInput.vue'
import ReturnCalculator from './helpers/ReturnCalculator.vue'
import SavingsRateCalculator from './helpers/SavingsRateCalculator.vue'

export default defineComponent({
  props: ['question', 'isLoading', 'data'],
  emits: ['save', 'back'],
  components: {
    AssetAllocation,
    RadioGroup,
    NButton,
    SliderInput,
    SliderOptions,
    VerticalOptions,
    PortfolioSize,
    TextArea,
    TextInput,
    ReturnCalculator,
    SavingsRateCalculator
  },
  setup (_, { emit }) {
    const isValid = ref(false)
    const questionRef = ref()
    const submit = () => {
      if (!isValid.value) {
        return
      }
      const data = questionRef.value.save()
      emit('save', data)
    }

    const back = () => {
      emit('back')
    }

    const helperValue = ref()

    const onHelperValue = (value: number) => {
      helperValue.value = value
    }

    return {
      submit,
      back,
      questionRef,
      isValid,
      helperVisible: ref(0),
      message: ref(undefined),
      onHelperValue,
      helperValue
    }
  }
})
