import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "slider" }
const _hoisted_2 = { class: "input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_slider = _resolveComponent("n-slider")!
  const _component_n_input = _resolveComponent("n-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_n_slider, {
      value: _ctx.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      step: _ctx.step,
      max: _ctx.options.length - 1,
      min: 0,
      formatTooltip: _ctx.formatTooltip,
      "on-update:value": _ctx.callback,
      "show-tooltip": ""
    }, null, 8, ["value", "step", "max", "formatTooltip", "on-update:value"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_n_input, {
        style: {"text-align":"right"},
        value: _ctx.inputValue?.toString(),
        "onUpdate:value": _ctx.setValue
      }, {
        suffix: _withCtx(() => [
          _createTextVNode(" € ")
        ]),
        _: 1
      }, 8, ["value", "onUpdate:value"])
    ])
  ]))
}