
import { computed, defineComponent, Ref, ref } from 'vue'
import { questions } from '../questions'
import QuestionFrame from './components/QuestionFrame.vue'
import { NConfigProvider, GlobalThemeOverrides, NProgress, useThemeVars } from 'naive-ui'
import WelcomeScreen from './components/WelcomeScreen.vue'
import ThankYouScreen from './components/ThankYouScreen.vue'
import AppFooter from './components/AppFooter.vue'

const YEAR = '2023'

function uuidv4 () {
  // @ts-expect-error Not sure
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: number) =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

enum Status {
  Init, Question, Complete
}

enum StorageKeys {
  Uuid = 'uuid',
  Data = 'data',
  Year = 'year'
}

export default defineComponent({
  components: {
    QuestionFrame,
    NConfigProvider,
    NProgress,
    WelcomeScreen,
    ThankYouScreen,
    AppFooter
  },
  setup () {
    let uuid: string = window.localStorage.getItem(StorageKeys.Uuid) ?? uuidv4()

    const showContinueOption = ref(!!window.localStorage.getItem(StorageKeys.Data) && window.localStorage.getItem(StorageKeys.Year) === YEAR)
    const status = ref(Status.Init)
    const questionIndex = ref(0)
    const isLoading = ref(false)

    const question = computed(() => {
      return questions[questionIndex.value]
    })
    const percentage = computed(() => {
      return Math.ceil(questionIndex.value / questions.length * 100)
    })
    const nthQuestionOfTotal = computed(() => {
      return `${questionIndex.value}/${questions.length}`
    })
    const data: Ref<Record<string, string>> = ref({})

    const next = () => {
      if (questionIndex.value + 1 < questions.length) {
        questionIndex.value++
      } else {
        status.value = Status.Complete
      }
    }

    const back = () => {
      if (questionIndex.value === 0) {
        status.value = Status.Init
      } else {
        questionIndex.value--
      }
    }

    const save = async (value: string) => {
      data.value[question.value.key] = value

      window.localStorage.setItem(StorageKeys.Year, YEAR)
      window.localStorage.setItem(StorageKeys.Data, JSON.stringify(data.value))
      window.localStorage.setItem(StorageKeys.Uuid, uuid)

      isLoading.value = true

      try {
        await persist()
      } finally {
        next()
        isLoading.value = false
      }
    }

    const persist = () => {
      const url = 'https://b1b8p3wfvd.execute-api.eu-north-1.amazonaws.com/production/taavi/fv-poll'

      return fetch(url + (uuid ? '?uuid=' + uuid : ''), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data.value)
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.uuid) {
            uuid = data.uuid
            window.localStorage.setItem(StorageKeys.Uuid, uuid)
          }
        })
    }

    const start = () => {
      status.value = Status.Question
      if (showContinueOption.value && Object.keys(JSON.parse(window.localStorage.getItem(StorageKeys.Data) ?? '{}')).length === questions.length) {
        uuid = uuidv4()
        data.value = {}
      }
    }

    const continuePrevious = () => {
      status.value = Status.Question
      data.value = JSON.parse(window.localStorage.getItem(StorageKeys.Data) ?? '{}')
      const lastFilled = Object.keys(data.value).length
      questionIndex.value = lastFilled - 1
    }

    const green = useThemeVars().value.successColor
    const themeOverrides: GlobalThemeOverrides = {
      common: {
      },
      Radio: {
        buttonColorActive: green,
        buttonTextColorActive: '#FFF',
        buttonBorderColorActive: green,
        buttonBoxShadowHover: `inset 0 0 0 1px ${green}`,
        buttonBorderColor: '#000'
      },
      Button: {
        colorFocus: green,
        textColorFocus: '#000'
      }
    }

    return {
      continuePrevious,
      showContinueOption,
      Status,
      status,
      start,
      uuid,
      themeVars: useThemeVars(),
      save,
      back,
      data,
      percentage,
      nthQuestionOfTotal,
      isLoading,
      question,
      themeOverrides
    }
  }
})
