
import { computed, defineComponent, ref } from 'vue'
import { NInput } from 'naive-ui'
import { validator, validatorEvents } from './parts'

export default defineComponent({
  props: ['question', 'data'],
  components: {
    NInput
  },
  emits: [
    ...validatorEvents
  ],
  setup (_, context) {
    const value = ref(_.data[_.question.key])

    const validations = computed(() => {
      return false
    })

    const { isValid } = validator(validations, context)

    const save = () => {
      return value.value
    }

    return {
      value,
      save,
      validations,
      isValid
    }
  }
})
