
import { defineComponent } from 'vue'

export default defineComponent({
  props: [],
  emits: ['start'],
  components: {},
  setup (_, { emit }) {
    const start = () => {
      emit('start')
    }

    return {
      start
    }
  }
})
