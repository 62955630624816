
import { computed, defineComponent, ref, watch } from 'vue'
import { NInput, NModal, NCard, NForm, NFormItem } from 'naive-ui'

export default defineComponent({
  props: ['question', 'data', 'isVisible'],
  emits: ['value'],
  components: {
    NInput,
    NModal,
    NCard,
    NForm,
    NFormItem
  },
  setup (_, context) {
    const modelRef = ref({
      income: '',
      savings: '0'
    })

    const savingsRate = computed(() => {
      const income = parseInt(modelRef.value.income)
      const savings = parseInt(modelRef.value.savings)

      if (isNaN(income) || isNaN(savings)) {
        return '-'
      }

      const ret = Math.round(savings / income * 10000) / 100
      context.emit('value', Math.round(ret))

      return ret
    })

    const show = ref(false)

    watch(() => _.isVisible, (val: boolean) => {
      show.value = !!val
    })

    return {
      model: modelRef,
      savingsRate,
      show
    }
  }
})
