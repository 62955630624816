
import { computed, defineComponent, ref } from 'vue'
import { NSlider, NInput } from 'naive-ui'
import { validator, validatorEvents } from './parts'

const options = [
  ...Array(11).fill(0).map((_, i) => (i + 0) * 1000),
  ...Array(20).fill(0).map((_, i) => (i + 1) * 5000),
  ...Array(20).fill(0).map((_, i) => (i + 1) * 50000),
  ...Array(20).fill(0).map((_, i) => (i + 1) * 500000)
]

export default defineComponent({
  props: ['question', 'data'],
  components: {
    NSlider,
    NInput
  },
  emits: [
    ...validatorEvents
  ],
  setup (_, context) {
    const value = ref(_.data[_.question.key]?.val ?? 0)
    const inputValue = ref(_.data[_.question.key]?.user_input ?? 0)

    const moneyValue = computed(() => {
      return options[value.value]
    })

    const validations = computed(() => {
      return false
    })

    const { isValid } = validator(validations, context)

    const save = () => {
      return {
        val: value.value,
        value: moneyValue.value,
        user_input: inputValue.value
      }
    }

    const formatTooltip = () => {
      if (moneyValue.value >= 10000000) {
        return 'üle 10 000 000 €'
      }
      return moneyValue.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' €'
    }

    const step = computed(() => {
      return 1
    })

    const setValue = (input: string) => {
      const nr = parseInt(input)

      if (isNaN(nr)) {
        inputValue.value = ''
        return
      }

      inputValue.value = nr
      value.value = Math.max(0, options.findIndex((val, i) => {
        const nextVal = options[i + 1]
        if (!nextVal) {
          return true
        }
        if (val >= nr) {
          return true
        }
        if (nr - val < nextVal - nr) {
          return true
        }
        return false
      }))
    }

    const callback = (val: number) => {
      value.value = val
      inputValue.value = moneyValue.value
    }

    return {
      value,
      inputValue,
      moneyValue,
      save,
      validations,
      isValid,
      formatTooltip,
      step,
      setValue,
      options,
      callback
    }
  }
})
