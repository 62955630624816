
import { computed, defineComponent, Ref, ref } from 'vue'
import { NSlider, NInput, NIcon, NTooltip } from 'naive-ui'
import { validator, validatorEvents } from './parts'
import QuestionMark from '@vicons/fluent/QuestionCircle16Filled'

export default defineComponent({
  props: ['question', 'data'],
  components: {
    NSlider,
    NIcon,
    NInput,
    NTooltip
  },
  emits: {
    ...validatorEvents
  },
  setup (_, context) {
    const assetClasses = ref([{
      key: 'equities',
      title: 'Väärtpaberid',
      tooltip: 'Aktsiad, ETF\'d, fondid, osalus mitte-noteeritud ettevõtetes (välja-arvatud iduettevõtted)',
      value: _.data[_.question.key]?.equities ?? 0
    }, {
      key: 'bonds',
      title: 'Laenuinstrumendid',
      tooltip: 'Võlakirjad, laenud (sh. väljastatud laenud ühisrahastusportaalide kaudu)',
      value: _.data[_.question.key]?.bonds ?? 0
    }, {
      key: 'real_estate',
      title: 'Kinnisvara',
      tooltip: 'Korterid, majad, maad jms. kinnisvaraobjektid',
      value: _.data[_.question.key]?.real_estate ?? 0
    }, {
      key: 'startups',
      title: 'Iduettevõtted',
      tooltip: 'Alustavad ettevõtted',
      value: _.data[_.question.key]?.startups ?? 0
    }, {
      key: 'cash',
      title: 'Raha',
      tooltip: 'Raha ja raha ekvalendid',
      value: _.data[_.question.key]?.cash ?? 0
    }, {
      key: 'commodities',
      title: 'Kaupvarad',
      tooltip: 'Toormed, väärismetallid jms.',
      value: _.data[_.question.key]?.commodities ?? 0
    }, {
      key: 'crypto',
      title: 'Krüpto',
      tooltip: 'Krüptovarad',
      value: _.data[_.question.key]?.crypto ?? 0
    }, {
      key: 'alternative',
      title: 'Alternatiivsed varad',
      tooltip: 'Näiteks veinid, autod, mündid jms. esemed mille väärtus aja jooksul tõuseb',
      value: _.data[_.question.key]?.alternative ?? 0
    }])

    const total = computed(() => assetClasses.value.reduce((sum, item) => sum + (!item.value ? 0 : item.value), 0))

    const validations = computed(() => {
      if (total.value !== 100) {
        return 'Varaklasside osakaalud peavad olema kokku 100%'
      }
      return false
    })

    const { isValid } = validator(validations, context)

    const save = () => {
      const data = assetClasses.value.reduce<Record<string, number>>((values, item) => {
        values[item.key] = item.value ?? 0
        return values
      }, {})
      return data
    }

    const update = (val: string, asset: Ref) => {
      if (isNaN(parseInt(val))) {
        asset.value = val
      } else {
        asset.value = parseInt(val)
      }
    }

    return {
      save,
      update,
      total,
      assetClasses,
      validations,
      isValid,
      QuestionMark
    }
  }
})
