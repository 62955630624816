import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d52bb44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_form_item = _resolveComponent("n-form-item")!
  const _component_n_form = _resolveComponent("n-form")!
  const _component_n_card = _resolveComponent("n-card")!
  const _component_n_modal = _resolveComponent("n-modal")!

  return (_openBlock(), _createBlock(_component_n_modal, {
    show: _ctx.show,
    "onUpdate:show": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.show) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_card, {
        style: {"width":"600px"},
        title: "Säästumäära kalkulaator",
        bordered: false,
        size: "huge",
        role: "dialog",
        "aria-modal": "true"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_n_form, {
            ref: "formRef",
            model: _ctx.model
          }, {
            default: _withCtx(() => [
              _createVNode(_component_n_form_item, {
                path: "income",
                label: "Aasta jooksul saadud sissetulekud kokku"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_n_input, {
                    value: _ctx.model.income,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.income) = $event)),
                    onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
                    placeholder: ""
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_n_form_item, {
                path: "savings",
                label: "Aasa jooksul säästetud summa kokku"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_n_input, {
                    value: _ctx.model.savings,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.savings) = $event)),
                    onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
                    placeholder: ""
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h1", null, "Säästumäär: " + _toDisplayString(_ctx.savingsRate) + "%", 1),
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.show = false))
            }, "sulge")
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}