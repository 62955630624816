import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c7ca0fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_form_item = _resolveComponent("n-form-item")!
  const _component_n_form = _resolveComponent("n-form")!
  const _component_n_card = _resolveComponent("n-card")!
  const _component_n_modal = _resolveComponent("n-modal")!

  return (_openBlock(), _createBlock(_component_n_modal, {
    show: _ctx.show,
    "onUpdate:show": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.show) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_card, {
        style: {"width":"600px"},
        title: "Portfelli tootluse kalkulaator",
        bordered: false,
        size: "huge",
        role: "dialog",
        "aria-modal": "true"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_n_form, {
            ref: "formRef",
            model: _ctx.model
          }, {
            default: _withCtx(() => [
              _createVNode(_component_n_form_item, {
                path: "startValue",
                label: "Portfelli väärtus aasta alguses"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_n_input, {
                    value: _ctx.model.startValue,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.startValue) = $event)),
                    onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
                    placeholder: ""
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_n_form_item, {
                path: "endValue",
                label: "Portfelli väärtus aasta lõpus"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_n_input, {
                    value: _ctx.model.endValue,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.endValue) = $event)),
                    onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
                    placeholder: ""
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_n_form_item, {
                path: "outflow",
                label: "Aasta jooksul portfellist välja võetud summa"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_n_input, {
                    value: _ctx.model.outflow,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.outflow) = $event)),
                    onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
                    placeholder: ""
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_n_form_item, {
                path: "outflow",
                label: "Aasta jooksul portfelli juurde lisatud summa"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_n_input, {
                    value: _ctx.model.inflow,
                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.inflow) = $event)),
                    onKeydown: _cache[7] || (_cache[7] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
                    placeholder: ""
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h1", null, "Tootlus: " + _toDisplayString(_ctx.portfolioReturn) + "%", 1),
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.show = false))
            }, "sulge")
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}