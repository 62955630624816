import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c6633a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slider" }
const _hoisted_2 = { class: "input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_slider = _resolveComponent("n-slider")!
  const _component_n_input = _resolveComponent("n-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_n_slider, {
        value: _ctx.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        step: 1,
        min: _ctx.question.min,
        max: _ctx.question.max,
        formatTooltip: _ctx.question.formatter
      }, null, 8, ["value", "min", "max", "formatTooltip"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_n_input, {
        style: {"text-align":"right"},
        value: _ctx.value?.toString(),
        "onUpdate:value": _ctx.update
      }, _createSlots({ _: 2 }, [
        (_ctx.question.suffix)
          ? {
              name: "suffix",
              fn: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.question.suffix), 1)
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["value", "onUpdate:value"])
    ])
  ]))
}