
import { defineComponent } from 'vue'

export default defineComponent({
  props: [],
  emits: [],
  components: {},
  setup () {
    return {
    }
  }
})
