
import { computed, defineComponent, ref } from 'vue'
import { NButtonGroup, NButton } from 'naive-ui'
import { validator, validatorEvents } from './parts'

export default defineComponent({
  props: ['question', 'data'],
  components: {
    NButtonGroup,
    NButton
  },
  emits: [
    ...validatorEvents
  ],
  setup (_, context) {
    const value = ref(_.data[_.question.key] ?? undefined)
    const validations = computed(() => {
      return !value.value
    })

    const { isValid } = validator(validations, context)

    const save = () => {
      return value.value
    }

    const select = (option: { key: string }) => {
      value.value = option.key
    }

    return {
      value,
      save,
      validations,
      isValid,
      select
    }
  }
})
