
import { computed, defineComponent, ref } from 'vue'
import { NSlider } from 'naive-ui'
import { validator, validatorEvents } from './parts'

export default defineComponent({
  props: ['question'],
  components: {
    NSlider
  },
  emits: [
    ...validatorEvents
  ],
  setup (_, context) {
    const value = ref(0)
    const validations = computed(() => {
      return false
    })

    const { isValid } = validator(validations, context)

    const save = () => {
      return value.value
    }

    const formatTooltip = (value: number) => {
      return _.question.marks[value]
    }

    return {
      value,
      save,
      validations,
      isValid,
      formatTooltip
    }
  }
})
