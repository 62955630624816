import { computed, watch, ComputedRef, SetupContext } from 'vue'

export const validatorEvents : ('isValid' | 'errorMessage')[] = ['isValid', 'errorMessage']

export const validator = (validations: ComputedRef<string | boolean>, context: SetupContext<('isValid' | 'errorMessage')[]>) => {
  const isValid = computed(() => !validations.value)

  watch(isValid, (isValid) => {
    context.emit('isValid', isValid)
  }, { immediate: true })

  watch(validations, (message) => {
    context.emit('errorMessage', message)
  }, { immediate: true })

  return {
    isValid
  }
}
