import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33010d34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_input = _resolveComponent("n-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_n_input, {
        placeholder: "",
        type: "textarea",
        value: _ctx.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        maxlength: _ctx.question.max,
        "show-count": ""
      }, null, 8, ["value", "maxlength"])
    ])
  ]))
}