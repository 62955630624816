
import { computed, defineComponent, ref, watch } from 'vue'
import { NInput, NModal, NCard, NForm, NFormItem } from 'naive-ui'

export default defineComponent({
  props: ['question', 'data', 'isVisible'],
  emits: ['value'],
  components: {
    NInput,
    NModal,
    NCard,
    NForm,
    NFormItem
  },
  setup (_, context) {
    const modelRef = ref({
      startValue: '',
      endValue: (_.data?.portfolio_size?.user_input || '').toString(),
      outflow: '0',
      inflow: '0'
    })

    const portfolioReturn = computed(() => {
      const startValue = parseInt(modelRef.value.startValue)
      const endValue = parseInt(modelRef.value.endValue)
      const outflow = parseInt(modelRef.value.outflow)
      const inflow = parseInt(modelRef.value.inflow)

      if (isNaN(startValue) || isNaN(endValue) || isNaN(outflow) || isNaN(inflow)) {
        return '-'
      }

      const ret = Math.round((((endValue + outflow) - (startValue + inflow)) / (startValue + inflow)) * 10000) / 100
      context.emit('value', Math.round(ret))

      return ret
    })

    const show = ref(false)

    watch(() => _.isVisible, (val: boolean) => {
      show.value = !!val
    })

    return {
      model: modelRef,
      portfolioReturn,
      show
    }
  }
})
