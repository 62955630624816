import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_button_group = _resolveComponent("n-button-group")!

  return (_openBlock(), _createBlock(_component_n_button_group, { vertical: "" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.options, (option) => {
        return (_openBlock(), _createBlock(_component_n_button, {
          key: option.key,
          type: _ctx.value === option.key ? 'success' : undefined,
          onClick: ($event: any) => (_ctx.select(option)),
          size: "large"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(option.label), 1)
          ]),
          _: 2
        }, 1032, ["type", "onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}