
import { defineComponent } from 'vue'
import { NButton, NH1, NText, NAlert, NTag } from 'naive-ui'

export default defineComponent({
  props: ['canContinue'],
  emits: ['start', 'continuePrevious'],
  components: {
    NButton,
    NH1,
    NText,
    NAlert,
    NTag
  },
  setup (_, { emit }) {
    const start = () => {
      emit('start')
    }

    const continuePrevious = () => {
      emit('continuePrevious')
    }

    return {
      start,
      continuePrevious
    }
  }
})
